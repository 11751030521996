(function ($) {
  Drupal.behaviors.productAwardsV1 = {
    /**
     * Display the product awards by reading the product attributes, parsing them, then returning
     * the appropriate variables from the translations.
     */
    attach: function (context) {
      var $module = $('.js-product-awards-v1:not(.attached)', context);

      $module.each(function () {
        var $me = $(this);

        $me.addClass('attached');

        var awards = $(this).data('awards');

        // Split continuous strings and commas - https://gist.github.com/fcamel/1081892
        awards = $.map(awards.split(','), $.trim);

        _.each(awards, function (award) {
          if (!_.isUndefined(site.translations.product_awards[award])) {
            var rendered = site.template.get({
              name: 'product_awards_item',
              data: site.translations.product_awards[award]
            });

            $me.append(rendered);
          }
        });
      });
    }
  };
})(jQuery);
